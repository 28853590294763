body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f8f9fb;
  }
  
  .kanban-board {
    display: flex;
    gap: 1rem;
    padding: 1rem .5rem;
    overflow-x: auto;
  
    .kanban-column {
      flex: 1;
      min-width: 200px;
      background: #ffffff;
      border-radius: 8px;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
  
      .kanban-column-header {
        background-color: #f4f6f9;
        color: #333;
        font-weight: bold;
        padding: 10px;
        border-bottom: 1px solid #e6e9ed;
        text-align: center;
      }
  
      .kanban-column-content {
        padding: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        overflow-y: auto;
      }

      .kanban-card {
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        padding: 10px;

        &.analysis\/design {
          border-left: 3px solid #00FF00; 
        }

        &.development {
          border-left: 3px solid #9fa9b7;
        }

        &.completed {
          border-left: 3px solid #198754;
        }

        &.new {
          border-left: 3px solid #326fd1;
        }

        &.issue {
          border-left: 3px solid #FFA500;
        }

        &.testing {
          border-left: 3px solid #eec137;
        }

        &.resolved {
          border-left: 3px solid #0dcaf0;
        }

        &.prod {
          border-left: 3px solid #00FFFF;
        }

        &.clarification {
          border-left: 3px solid #FF00FF;
        }

        &.reopen {
          border-left: 3px solid red;
        }

        &.onhold {
          border-left: 3px solid #000000;
        }

        &.implementation {
          border-left: 3px solid #000080;
        }

        &.replicate {
          border-left: 3px solid #A52A2A;
        }

        &.duplicate {
          border-left: 3px solid #808080;
        }

        &.review {
          border-left: 3px solid #6366f1;
        }
      
        .kanban-card-header {
          display: flex;
          justify-content: space-between;
          font-size: 12px;
          color: #555;
      
          .kanban-task-id {
            font-size: 15px;
            cursor: pointer;
            font-weight: bold;
            color: #f17038;
          }
      
          .kanban-assignee {
            background-color: #eee;
            padding: 2px 6px;
            border-radius: 50%;
            color: #333;
            font-weight: bold;
            text-align: center;
            cursor: default;
          }
        }
      
        .kanban-card-title {
          font-size: 14px;
          margin: 8px 0;
          color: #333;
          display: -webkit-box;
          // display: box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 150px;
        }
      
        .kanban-card-details {
          margin-top: 8px;
          padding: 5px;
          background-color: #fafafa;
          border-radius: 4px;
      
          .kanban-detail {
            font-size: 12px;
            color: #555;
            margin-bottom: 4px;
          }
        }
      
        .kanban-card-footer {
          display: flex;
          gap: 5px;
      
          .tag {
            background-color: #eef2f7;
            color: #555;
            padding: 2px 6px;
            font-size: 12px;
            border-radius: 3px;
      
            &.tag-improvement {
              background-color: #fdecec;
              color: #e57373;
            }
          }
        }

        .kanban-card-footer-end {
          display: flex;
          gap: 5px;
      
          .tag-assignName {
            background-color: #eef2f7;
            color: #555;
            padding: 2px 6px;
            font-size: 12px;
            border-radius: 3px;
          }
        }
      }
      
      .kanban-column-footer {
        padding: 8px;
        text-align: left;
        border-top: 1px solid #e6e9ed;
        font-size: 12px;
        color: #555;
  
        .kanban-assignee-summary {
          margin: 4px 0;
          padding: 4px;
          background-color: #eef2f7;
          border-radius: 4px;
          display: inline-block;
          color: #333;
          font-weight: bold;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
      }  

      &.no-tasks {
        background-color: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: #555;
        text-align: center;
  
        div {
          font-size: 14px;
          color: #888;
          padding: 10px;
        }
      }
      
    }
  }
  
  .dropdown-container {
    display: flex;
    justify-content: flex-end;
    margin-top: -6rem;
    margin-bottom: 10px;
  }

  .dropdown-sprint-container{
    display: flex;
    justify-content: flex-end;
    margin-top: -40px;
    margin-bottom: 10px;
  }

  .count-container{
    display: flex;
    justify-content: flex-start;
  }
  
  .kanban-assignees{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #007bff;
    color: #fff;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 8px;
  }

  .p-multiselect.p-multiselect-chip .p-multiselect-token {
    background: #007bff;
    color: #fff;
    padding: 0.25rem 0.4rem;
    }

  .p-multiselect-label {
    display: flex;
    align-items: center;
    height: 100%;
}

  .kanban-total-tasks {
    // display: inline-block;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }