.tabs {
    // overflow: auto;

    // .tabs__wrap {
    //     min-width: min-content;
    // }

    .nav-tabs {
        flex-wrap: nowrap;

        li {
            .nav-link {
                padding: 10px 20px;
                margin-right: 0;
                transition: background-color 0.3s;
                cursor: pointer;

                // @include themify($themes) {
                //     color: themed('colorText');
                // }

                &:hover,
                &:focus,
                &:active {
                    outline: none;

                    // @include themify($themes) {
                    //     background-color: themed('colorHover');
                    //     border-color: themed('colorHover');
                    //     border-bottom-color: #dddddd;
                    // }
                }
            }

            .nav-link.active,
            .nav-link.active:focus,
            .nav-link.active:hover {
                background-color: transparent;

                // @include themify($themes) {
                //     border-color: #dddddd;
                //     border-bottom-color: themed('colorBackground');
                //     color: themed('colorText');
                // }
            }

            // &.disabled .nav-link {
            //     color: $color-additional;
            // }
        }
    }

    // .tab-pane {
    //     padding-top: 20px;
    // }

    &.tabs--bordered-top {

        .nav-tabs {
            li .nav-link {
                border-top: 2px solid transparent;
                border-radius: 0;
            }

            li .nav-link.active {

                &,
                &:focus,
                &:hover {
                    border-radius: 0;
                    // border-top: 2px solid $color-accent;
                }
            }
        }
    }

    .timeline__content {
        // width: calc(50% - 52px);
        padding: 20px 25px 20px 20px;
        border-radius: 5px;
        position: relative;
        background-color: #ffffff;
    }

    .timeline__content:after {
        content: '';
        position: absolute;
        right: -20px;
        top: 20px;
        border: 10px solid transparent;
        border-left: 10px solid #ffffff;
    }

    .timeline__contents {
        // width: calc(50% - 52px);
        padding: 20px 25px 20px 20px;
        border-radius: 5px;
        position: relative;
        background-color: #ffffff;
    }

    .timeline__icon {
        position: relative;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f0f0f0;
    }
    
    .timeline__icon img {
        height: 100%;
        width: auto;
        object-fit: cover;
        border-radius: 50%;
    }
    
    .defaultImgStyle {
        border-radius: 50%;
    }

    &.tabs--bordered-bottom {

        .nav-tabs {

            li .nav-link {
                border-color: transparent;
                border-bottom: 1px solid transparent;
                padding-bottom: 11px;
            }

            li .nav-link.active {

                &,
                &:focus,
                &:hover {
                    border-radius: 0;
                    border-color: transparent;
                    // border-bottom: 2px solid $color-accent;
                    padding-bottom: 10px;
                }
            }
        }
    }

    &.tabs--justify {

        .nav-tabs {
            display: flex;
            flex-wrap: nowrap;

            li {
                width: 100%;

                a {
                    text-align: center;
                }
            }
        }
    }

    &.tabs--vertical {
        overflow-y: visible;

        .tabs__wrap {
            display: flex;
        }

        .nav-tabs {
            border-bottom: none;
            width: 90px;
            height: 100%;
            flex-wrap: wrap;

            li {
                margin-top: 0;
                width: 100%;

                .nav-link {
                    padding: 10px 10px 10px 0;
                    margin-right: 0;
                    transition: background-color 0.3s;
                    border-radius: 0;
                    border: none;

                    // @include themify($themes) {
                    //     color: themed('colorText');
                    //     border-right: 2px solid #dddddd;
                    // }

                    &:hover,
                    &:focus,
                    &:active {
                        outline: none;

                        // @include themify($themes) {
                        //     background-color: themed('colorHover');
                        // }
                    }
                }

                .nav-link.active,
                .nav-link.active:focus,
                .nav-link.active:hover {
                    background-color: transparent;

                    // @include themify($themes) {
                    //     border-right-color: $color-accent;
                    // }
                }

                // &.disabled .nav-link {
                //     color: $color-additional;
                // }
            }
        }

        .tab-pane {
            padding-top: 0;
            padding-left: 20px;
        }

        &.tabs--vertical-colored {

            .nav-tabs {
                li {

                    .nav-link {
                        border-right-color: #eeeeee;
                        padding-left: 10px;
                        text-align: center;
                    }

                    .nav-link.active,
                    .nav-link.active:focus,
                    .nav-link.active:hover {
                        background-color: #eeeeee;
                        border-right-color: #eeeeee;
                        color: #646777;
                    }
                }
            }
        }
    }
}


/* Main container */
.kanban__view__tabs {
  background-color: #f5f6f8;
  border-radius: 8px;
  margin-top: 1rem;
  // padding: 1.25rem;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
}

.kanban__view__tabs__wrap {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
}

/* Navigation tabs */
.kanban__view__nav__tabs {
  display: flex;
  border: none;
  padding: 0.5rem 0.5rem 0;
  background-color: #fff;
  gap: 0.25rem;
}

.kanban__view__nav__item .kanban__view__nav__link {
  padding: 0.75rem 1.25rem;
  color: #5a6474;
  border: none;
  border-radius: 6px 6px 0 0;
  transition: all 0.2s ease;
  font-weight: 500;
  position: relative;
}

.kanban__view__nav__item .kanban__view__nav__link:hover {
  color: #2c3e50;
  background-color: #f8f9fa;
}

.kanban__view__nav__item .history__custom__class.active {
  color: #0d9488;
  background-color: #f0fdfa;
}

.kanban__view__nav__item .comments__custom__class.active {
  color: #7c3aed;
  background-color: #f5f3ff;
}

.kanban__view__nav__item .history__custom__class.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #0d9488;
}

.kanban__view__nav__item .comments__custom__class.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #7c3aed;
}

/* Tab content */
.history__tab__content {
  padding: .5rem;
  background-color: #0d94880a;
}

.comments__tab__content {
  padding: .5rem;
  background-color: #7c3aed0a;
}

.kanban__view__tab__pane {
  animation: slideIn 0.3s ease-out;
}

/* Timeline content */
.timeline__content__history,
.timeline__content__comments,
.timeline__content__attachments {
  border-radius: 8px;
  padding: 12px;
}

.timeline__content__history {
  background-color: #f0fdfa;
  border: .5px solid #0d9488;
}

.timeline__content__comments {
  background-color: #f5f3ff;
  border: .5px solid #7c3aed;
}

.timeline__content__history h5,
.timeline__content__comments h5 {
  color: #2d3748;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.timeline__content__history h6,
.timeline__content__comments h6 {
  color: #718096;
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}

.timeline__content__history p,
.timeline__content__comments p {
  color: #4a5568;
  font-size: 13px;
  line-height: 1.6;
  margin-top: 0.75rem;
  margin-bottom: 0;
}

/* Timeline icon/image */
.timeline__profile_icon {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  // border: 2px solid #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;

}

.timeline__profile_icon img {
  height: 100%;
  width: auto;
  object-fit: cover;
  border-radius: 50%;
}

.defaultImgStyle {
  width: 100%;
  height: 100%;
  background-color: #e2e8f0;
}


/* Loading spinner */
.spinner-border {
  color: #1a73e8;
  width: 2rem;
  height: 2rem;
}

/* Empty states */
.kanban__view__tab__pane>div>div[style*="text-align: center"] {
  padding: 2.5rem 1rem;
  color: #718096;
  font-size: 0.925rem;
//   background-color: #f8fafc;
  border-radius: 8px;
  border: 1px dashed #e2e8f0;
}

/* Animation keyframes */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(8px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .kanban__view__nav__item .kanban__view__nav__link {
    padding: 0.6rem 1rem;
    font-size: 0.9rem;
  }

  .timeline__content__history,
  .timeline__content__comments {
    padding: 0.75rem;
  }

  .timeline__profile_icon {
    width: 36px;
    height: 36px;
  }
}

.kanban__view__spinner{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}