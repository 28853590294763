.FileGalleryView {
    .modal {
        display: block;
        position: fixed;
        z-index: 1000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-content {
        width: 80%;
        max-width: 1200px;
        // height: 600px;
        background-color: rgb(36, 34, 36);
        padding: 20px;
        position: relative;
        overflow: hidden;
        color: #f1f1f1;
        // display: flex;
        // justify-content: center;
        // align-items: center;
    }

    .close {
        color: #f1f1f1;
        width: auto;
        padding: 0 10px; /* Optional: Adds padding around the '×' */
        margin-left: auto; 
        text-align: center; 
        font-size: 28px;
        font-weight: bold;
        cursor: pointer;
    }

    .close:hover,
    .close:focus {
        color: #ddd;
        text-decoration: none;
        cursor: pointer;
    }

    .gallery-media {
        max-width: 100%;
        max-height: 500px;
        width: auto;
        height: auto;
        display: block;
        margin: 0 auto;
    }

    // .gallery-image-link {
    //     display: block;
    //     max-width: 100%;
    // }

    .gallery-index {
        position: absolute;
        top: 10px;
        left: 10px;
        color: #f1f1f1;
        padding: 5px;
    }

    .file-name-display {
        margin-top: 10px;
        color: #f1f1f1;
        font-size: 14px;
        text-align: center;
    }

    >button {
        color: $button-text-color;
        background-color: $color-template;
        text-transform: capitalize;
        border-radius: 20px;
        font-size: 10px;
        border-color: $color-template;
        // padding: 8px 16px;
    }

    .image-gallery-image {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    .image-gallery-play-button,
    .image-gallery-fullscreen-button {
        padding: 0px !important;
    }

    .image-gallery-thumbnail {
        margin-top: 10px;
        border-radius: 12px;
    }

    .image-gallery-thumbnail-image {
        margin-top: 0px;
    }
    .image-gallery-index{
        display: none;
    }
}